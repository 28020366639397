header {
  position: relative;
  width: 100vw;
  padding-left: 10px;

  &.disabled {
    .container {
      .buttonClose {
        background-color: $background-disabled;

        .icon {
          path {
            fill: $absolute-white;
          }
        }

        &:hover {
          background-color: $background-disabled;
          cursor: default;

          .icon {
            path {
              fill: $absolute-white;
            }
          }
        }
      }
    }
  }

  .container {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);

    .content {
      width: 100%;
      height: 30px;
      padding-right: 16px;
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);

      .titlepage {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
      }
    }

    .buttonClose {
      width: 30px;
      min-width: 30px;
      height: 30px;
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(center);
      @include align-items(center);
      @include transition(.2s);

      .icon {
        width: 10px;
        height: 10px;

        path {
          @include transition(.1s);
          fill: $absolute-black;
        }
      }

      &:hover {
        background-color: $background-error;
        cursor: pointer;

        .icon {
          path {
            fill: $absolute-white;
          }
        }
      }
    }
  }
}
