
$--elevation-0:  0 0 0 0 transparent;
$--elevation-1:  0px 2px 1px -1px rgba(120, 120, 143, 0.2), 0px 1px 1px 0px rgba(120, 120, 143, 0.14), 0px 1px 3px 0px rgba(120, 120, 143, 0.12);
$--elevation-2:  0px 3px 1px -2px rgba(120, 120, 143, 0.2), 0px 2px 2px 0px rgba(120, 120, 143, 0.14), 0px 1px 5px 0px rgba(120, 120, 143, 0.12);
$--elevation-3:  0px 3px 3px -2px rgba(120, 120, 143, 0.2), 0px 3px 4px 0px rgba(120, 120, 143, 0.14), 0px 1px 8px 0px rgba(120, 120, 143, 0.12);
$--elevation-5:  0px 3px 5px -1px rgba(120, 120, 143, 0.2), 0px 5px 8px 0px rgba(120, 120, 143, 0.14), 0px 1px 14px 0px rgba(120, 120, 143, 0.12);
$--elevation-8:  0px 5px 5px -3px rgba(120, 120, 143, 0.2), 0px 8px 10px 1px rgba(120, 120, 143, 0.14), 0px 3px 14px 2px rgba(120, 120, 143, 0.12);
$--elevation-13: 0px 7px 8px -4px rgba(120, 120, 143, 0.2), 0px 13px 19px 2px rgba(120, 120, 143, 0.14), 0px 5px 24px 4px rgba(120, 120, 143, 0.12);


#address,
#storeinfo,
#website,
#stockcontrol {
  position: relative;
  height: 100%;
  width: 100vw;

  &.error {
    .container {
      label {
        &[for="name"],
        &[for="autocomplete"] {
          color: $color-error;
        }
      }

      input {
        &#name,
        &#autocomplete {
          border-color: $color-error;
        }
        &#identifier {
          &::placeholder {
            color: $color-error;
          }
        }
      }

    }
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);

    .containerIdentifier {
      position: relative;
      @include flexbox();
      @include flex-direction(column);
      width: 100%;
      margin-top: 20px;

      .label {
        font-size: 14px;
        letter-spacing: 0.6px;
        font-weight: 500;
        position: absolute;
        top: 10px;
        left: 10px;
        color: $color-label-identifier;
      }

      .feedback {
        opacity: 0;
        position: absolute;
        width: calc(100% - 10px);
        height: 20px;
        top: 35px;
        left: 10px;
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        @include transition(.2s);

        .dot {
          position: relative;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: $absolute-black;
          margin-right: 5px;
          @include transition(.2s);
        }

        .msg {
          position: relative;
          width: auto;
          font-size: 12px;
          font-weight: 500;
          color: $absolute-black;
          @include transition(.2s);
        }
      }

      .ring-container {
        position: absolute;
        left: 3px;
        top: 35px;
      }

      &.available,
      &.unavailable {
        .feedback {
          opacity: 1;
        }
      }

      &.available {
        .feedback {
          .dot {
            background-color: $background-label-available;
          }

          .msg {
            color: $color-label-available;
          }
        }
      }

      &.unavailable {
        input {
          &#identifier {
            color: $color-error;
          }
        }

        .feedback {
          .dot {
            background-color: $background-error;
          }

          .msg {
            color: $color-error;
          }
        }
      }
    }

    .titlestep {
      font-size: 15px;
      font-weight: 700;
      text-align: center;
      margin: 15px 0 30px;

      .step {
        letter-spacing: 1px;
        padding-right: 5px;
      }
    }

    label {
      font-size: 14px;
      font-weight: 600;
      margin: 35px 0 7px;

      &:first-of-type {
        margin: 0 0 7px;
      }
    }

    input {
      height: 35px;
      padding: 0 10px;
      border: 1px solid $color-border;
      font-size: 14px;

      &#autocomplete {
        padding: 0 25px 0 10px;
      }

      &#identifier {
        height: 35px;
        padding: 0 10px 0 193px;
        font-weight: 600;
        text-transform: lowercase;
        color: $background-button-hover;
        @include transition(.2s);

        &::placeholder {
          color: $background-button-default;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .content {
      @include flexbox();
      @include flex-direction(row);

      .card {
        position: relative;
        height: 219px;
        width: 160px;
        margin-left: 70px;
        box-shadow: $--elevation-2;
        border: 1px solid #e6e7e8;
        margin-bottom: 2.5px;
        border-radius: 3px;

        &.hidden {
          box-shadow: $--elevation-0;
          border: none;

          .prodImg,
          .prodDetail {
            display: none;
          }
        }

        .prodImg {
          position: relative;

          img {
            width: 158px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }
          .hasStock {
            display: none;
            @include justify-content(center);
            @include align-items(center);
            background-color: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;


            &.active {
              @include flexbox();
            }

            .text {
              font-size: 12px;
              font-weight: 400;
              text-align: center;
              padding: 2.5px 10px;
              background-color: $absolute-white;
              color: #999;
              border: 1px solid #ccc;
            }
          }

          &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              box-shadow: inset 0 0 20px 20px rgba(0,0,0,.04);
          }
        }

        .prodDetail {
          padding: 7.5px;
          @include flexbox();
          @include flex-direction(column);
          @include align-items(flex-start);

          .category {
            padding: 3px 10px 2px;
            background: #f1f2f2;
            text-align: center;
            letter-spacing: .5px;
            font-size: 8px;
            font-weight: 500;
            color: #a7a9ac;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-transform: uppercase;
          }
          .name {
            max-height: 28px;
            font-size: 12px;
            font-weight: 700;
            margin-top: 5px;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .code {
            line-height: 15px;
            font-size: 8px;
            font-weight: 500;
            color: #a7a9ac;
            text-transform: capitalize;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: auto;
          }
          .price {
            font-size: 16px;
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 7.5px;
          }
        }
      }
    }

    .checkbox {
      margin: 42px 0 7px;
      text-align: center;

      label {
        line-height: 22px;
        height: 22px;
      }

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        height: 0px;

        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
        }

        & + label:before {
          content: '';
          margin: -2px 10px 0 0;
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: white;
          border: 1px solid $absolute-black;
        }

        // Box checked
        // &:checked + label:before {
        //   background: $absolute-black;
        // }

        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        &:checked + label:after {
          content: 'L';
          position: absolute;
          left: 21px;
          top: 2px;
          width: 1px;
          height: 1px;
          font-size: 18px;
          font-weight: 300;
          transform: rotateY(180deg) rotate(-45deg);
        }
      }
    }

    .radiobox {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);

      div {
        margin: 5px 0;

        label {
          line-height: 22px;
          height: 22px;
        }

        input[type="radio"] {
          position: absolute;
          opacity: 0;
          height: 0px;

          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
          }

          & + label:before {
            content: '';
            margin: 0px 10px 0 0;
            display: inline-block;
            vertical-align: text-top;
            width: 14px;
            height: 14px;
            background: white;
            border-radius: 50%;
            border: 1px solid $absolute-black;
          }

          // Box checked
          // &:checked + label:before {
          //   background: $absolute-black;
          // }

          &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
          }

          &:disabled + label:before {
            box-shadow: none;
            background: #ddd;
          }

          &:checked + label:after {
            content: '';
            position: absolute;
            left: 4px;
            top: 4px;
            width: 8px;
            height: 8px;
            background-color: $absolute-black;
            border-radius: 50%;
          }
        }
      }
    }

    .containerInput {
      width: 100%;
      position: relative;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);

      .container-address-selected {
        cursor: pointer;
        width: 350px;
        left: 0px;
        top: 0px;
        border: 1px solid $color-border;
        background-color: $absolute-white;
        position: absolute;
        z-index: 1000;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;

        .content {
          position: relative;
          padding: 8px 25px 0 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          height: 33px;
          text-align: left;
          font-size: 14px;
          color: $absolute-black;

          .icon-close {
            position: absolute;
            top: 11px;
            right: 8px;
            width: 10px;
            height: 10px;

            path {
              fill-opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

#storeinfo {
  .container {
    max-width: unset;

    .content {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(center);
      @include align-items(center);

      .inputText {
        @include flexbox();
        @include flex-direction(column);
        width: 100%;
        max-width: 300px;
      }

      .inputFile {
        padding-left: 40px;
        cursor: pointer;

        .previewLogo {
          position: relative;
          height: 150px;
          width: 150px;
          border-radius: 50%;
          border: 1px solid #333;
          background-color: #dfdfdf;
          margin-bottom: 10px;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;

          &.hasLogo {
            border-color: transparent;
            background-color: transparent;
          }

          &.hasName {
            font-size: 60px;
            font-weight: 400;
            background-color: #333;
            color: #fff;
          }

          .logo {
            position: absolute;
            top: -1px;
            left: -1px;
            border-radius: 50%;
            height: 150px;
            width: 150px;
            object-fit: cover;
            box-shadow: inset 0 0 20px 20px rgba(0,0,0,0.04);
          }
        }

        .button {
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          color: $background-button-default;

          &:hover {
            color: $background-button-hover;
          }

          &.remove {
            color: $color-error;

            &:hover {
              color: $color-error;
            }
          }
        }
      }
    }
  }
}

#website {
  .container {
    max-width: 400px;
  }
}

#stockcontrol {
  .container {
    max-width: unset;
    margin: 0 35px 0 60px;
    width: calc(100% - 95px);

    .titlestep {
      margin: 12.5px 0;
    }

    label {
      font-size: 14px;
      font-weight: 500;
    }

    .content {
      @include justify-content(center);
    }
  }
}

.edit {
  .listpages {
    #website {
      .container {
        .checkbox {
          display: none;
        }
      }
    }
  }
}

.container-error {
  position: absolute;
  width: calc(100% - 10px);
  height: 20px;
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);

  .dot {
    position: relative;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: $background-error;
    margin-right: 5px;
  }

  .msg {
    position: relative;
    width: auto;
    font-size: 12px;
    font-weight: 500;
    color: $color-error;
  }
}

@media screen and (min-height: 411px) {
  #stockcontrol {
    .container {
      @include justify-content(center);

      .content {
        @include justify-content(center);
      }
    }
  }
}

@media screen and (min-width: 661px) {
  #stockcontrol {
    .container {
      @include justify-content(center);

      .content {
        @include justify-content(center);
      }
    }
  }
}
