.tab-list {
  padding-left: 0;
  // border-bottom: 1px solid $gray-kyte3;
}

hr {
  height: 2px;
  opacity: .3;
  width: 0;
  margin: 0;
  background: $absolute-black;
  border: none;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 7px 16px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  transition: all .2s ease-in-out;
  color: $absolute-black;

  &:nth-child(1) {
    width: 155px;
  }

  &:nth-child(2) {
    width: 96px;
  }

  &:nth-child(3) {
    width: 86px;
  }

  div {
    display: none;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 7px;

    &.error {
      display: inline-block;
      background-color: $background-error;
    }
  }

  &.disabled {
    color: $color-disabled;

    &:hover {
      cursor: default;

      hr {
        width: 0;
        left: 0;
        right: 0;
      }
    }
  }

  &:hover {
    hr {
      width: 100%;
      left: 0;
      right: 0;
    }
  }

  &.tab-list-active {
    font-weight: bold;

    hr {
      width: 100%;
      left: 0;
      right: 0;
      background: $background-button-default;
      opacity: 1;
    }
  }
}
