@mixin animation($values) {
  -webkit-box-animation: $values;
     -moz-box-animation: $values;
      -webkit-animation: $values;
  	      -ms-animation: $values;
              animation: $values;
}

@mixin animation-iteration-count($values) {
  -webkit-box-animation-iteration-count: $values;
     -moz-box-animation-iteration-count: $values;
      -webkit-animation-iteration-count: $values;
          -ms-animation-iteration-count: $values;
              animation-iteration-count: $values;
}

@mixin transform($values) {
  -webkit-box-transform: $values;
     -moz-box-transform: $values;
      -webkit-transform: $values;
  	      -ms-transform: $values;
              transform: $values;
}


.ring-container {
  position: relative;
  height: 21px;
  width: 21px;
  z-index: 5;
  background-image: radial-gradient($absolute-white 50%, transparent 50%)
}

.circle {
  width: 6px;
  height: 6px;
  background-color: $background-circle-pulse;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 7px;
}

.ringring {
  border: 2px solid $background-circle-pulse;
  border-radius: 30px;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 4px;
  top: 4px;
  @include animation(pulsate 1s ease-out);
  @include animation-iteration-count(infinite);
  opacity: 0.0
}

@-webkit-keyframes pulsate {
  0% {@include transform(scale(0.1, 0.1)); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {@include transform(scale(1.2, 1.2)); opacity: 0.0;}
}

@-moz-keyframes pulsate {
  0% {@include transform(scale(0.1, 0.1)); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {@include transform(scale(1.2, 1.2)); opacity: 0.0;}
}

@keyframes pulsate {
  0% {@include transform(scale(0.1, 0.1)); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {@include transform(scale(1.2, 1.2)); opacity: 0.0;}
}
