.slidebody {
  &.container {
    position: relative;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100vw;
    overflow: hidden;

    .listpages {
      position: relative;
      display: inline-flex;
      height: 100%;
      left: 0px;
      @include transition(all .5s cubic-bezier(1, 0, 0, 1));

      &.step0 {
        left: 0;
      }

      &.step1 {
        left: -100%;
      }

      &.step2 {
        left: -200%;
      }

      &.step3 {
        left: -300%;
      }
    }
  }
}
