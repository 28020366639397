footer {
  position: relative;
  width: 100vw;
  padding: 45px 10px;

  .container {
    position: relative;
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(center);

    button {
      width: auto;
      height: 35px;
      font-size: 14px;
      border: none;
      cursor: pointer;
      @include transition(.2s);

      &.buttonBack {
        padding: 0 26px;
        position: absolute;
        left: 0;
        background-color: transparent;
        color: $absolute-black;
        font-weight: 600;
      }

      &.buttonSave,
      &.buttonNext {
        width: 230px;
        font-weight: 700;
        color: $absolute-white;
        background-color: $background-button-default;

        &:hover {
          background-color: $background-button-hover;
        }

        &.disabled {
          background-color: $background-disabled;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);

          img {
            margin-left: 5px;
          }

          &:hover {
            cursor: default;
            background-color: $background-disabled;;
          }
        }
      }
    }
  }
}
