.flow {
  position: relative;
  height: 100vh;
  width: 100vw;
  @include flexbox();
  @include flex-direction(column);

  &.edit {
    #storeinfo,
    #address,
    #website {
      .container {
        .titlestep {
          display: none;
        }
      }
    }
  }
}
