* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Arial;
}

html {
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: $min-width-container;
  min-height: $min-height-container;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

body {
  min-width: $min-width-container;
  min-height: $min-height-container;
  background-color: $background-body;
  color: $absolute-black;

  .pac-container {
    width: 350px !important;
    border: 1px solid $color-border !important;
    border-top: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;

    &.pac-logo {
      &:after {
        content: "";
        padding: 0 !important;
        height: 0 !important;
        display: none !important;
        background-image: none !important;
      }
    }
  }
}

#pages {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.container-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 4px;
  display: inline-block;
  vertical-align: top;
  background-image: url('../assets/images/autocomplete-icons.png');
  background-size: 34px;

  // .icon-marker {
  //   background-position: -1px -161px;
  // }

  &.icon-marker-selected {
    background-position: -18px -161px;
  }

  &.icon-search {
    background-position: -1px -1px;
    right: 6px;
    position: absolute;
    top: 8px;
    margin: 0;
  }
}


