// Variables
$absolute-black: #000000;
$absolute-white: #ffffff;

$blue:#2877b7;
$blue-dark:#205f92;
$gray-darker: #6f6f6f;
$gray-dark :#959494;
$gray-light: #e6e7e8;
$gray: #a9b1be;
$red: #ff1b37;
$green: #50bc4c;

// System
$background-body: $absolute-white;
$background-button-default: $blue;
$background-button-hover: $blue-dark;
$color-button-default: $absolute-white;
$color-border: $gray-light;
$color-border-hr: $gray-darker;
$color-text-default: $absolute-black;
$color-label-identifier: $gray-dark;
$background-error: $red;
$color-error: $red;
$color-label-available: $green;
$background-label-available: $green;
$color-disabled: $gray;
$background-disabled: $gray;
$background-circle-pulse: $gray-light;
